<!-- operation-service -->
<template>
  <div class="operation-service">
    <!-- 查询表单 -->
    <div class="operation-service-form">
      <el-form :model="model" inline size="small" @submit.native.prevent>
        <el-form-item label="状态:">
          <service-status v-model="model.status" />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="QQ、微信、公众号"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="operation-service-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>
    </div>

    <!-- 数据列表 -->
    <div v-loading="loading" class="operation-service-list">
      <el-card
        v-for="item in services"
        class="operation-service-item"
        :body-style="{ padding: 0 }"
        :key="item._id"
      >
        <div class="item-info">
          <img class="info-bg" :src="item.bg" />

          <div class="info-qq">
            <span>Q Q号:</span>
            <span>{{ item.qq }}</span>
          </div>

          <div class="info-wechat">
            <span>微信号:</span>
            <span>{{ item.wechat }}</span>
          </div>

          <div class="info-wechat-official-account">
            <span>公众号:</span>
            <span>{{ item.wechatOfficialAccount }}</span>
          </div>

          <div
            class="info-status"
            :class="{ enabled: item.status === serviceStatusEnum.enabled }"
          >
            {{ item.status | serviceStatusText }}
          </div>
        </div>

        <div class="item-operate">
          <div class="operate-container">
            <el-switch
              :value="item.status === serviceStatusEnum.enabled"
              active-color="#67C23A"
              @click.native="handleTableRowSwitchClick(item)"
            />
          </div>

          <div class="operate-container">
            <el-button
              plain
              type="primary"
              icon="el-icon-edit"
              circle
              size="mini"
              @click="handleTableRowEditClick(item)"
            />
          </div>

          <div class="operate-container">
            <el-button
              plain
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="handleTableRowRemoveClick(item)"
            />
          </div>
        </div>
      </el-card>

      <empty-placeholder v-if="!services || !services.length" />
    </div>

    <!-- 分页器 -->
    <div class="operation-service-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑对话框 -->
    <service-edit v-model="showEditDialog" @on-ok="handleEditDialogOkClick" />

    <!-- 切换状态对话框 -->
    <service-switch
      v-model="showSwitchDialog"
      :content="serviceSwitchDialogContent"
      :ok-button-loading="switching"
      @on-close="handleSwitchDialogClose"
      @on-ok="handleSwitchDialogOkClick"
    />

    <!-- 删除对话框 -->
    <service-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleRemoveDialogClose"
      @on-ok="handleRemoveDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import ServiceStatus from '../../../components/service-status';
  import EmptyPlaceholder from '../../../components/empty-placeholder';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import recountPageNo from '../../../lib/recount-page-no';
  import serviceStatus from '../../../enum/service-status.js';
  import {
    updateServiceStatus,
    removeService,
  } from '../../../api/operation/service';

  export default {
    name: 'operation-service',
    components: {
      ServiceStatus,
      ServiceEdit: () =>
        import(/* webpackChunkName: 'service-edit' */ './service-edit'),
      ServiceSwitch: () =>
        import(
          /* webpackChunkName: 'service-switch' */ '../../../components/confirm'
        ),
      ServiceRemove: () =>
        import(
          /* webpackChunkName: 'service-remove' */ '../../../components/confirm'
        ),
      EmptyPlaceholder,
    },
    filters: {
      // 客服状态文案
      serviceStatusText(val) {
        return serviceStatus.map[val] || '';
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        showSwitchDialog: false,
        showRemoveDialog: false,
        loading: false,
        searching: false,
        switching: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('operation/service', ['services', 'total']),

      // 客服状态枚举值
      serviceStatusEnum() {
        return serviceStatus.enum;
      },
      // 客服状态映射列表
      serviceStatusList() {
        return serviceStatus.map;
      },
      // 当前操作的客服项对象
      serviceObj() {
        return this.services.find(item => item._id === this.operateId);
      },
      // 当前操作的客服对象的状态
      serviceObjStatus() {
        return this.serviceObj?.status;
      },
      // 切换状态对话框提示文案
      serviceSwitchDialogContent() {
        const status = this.serviceObjStatus;
        const statusEnum = serviceStatus.enum;
        const targetStatus =
          status === statusEnum.enabled
            ? statusEnum.disabled
            : statusEnum.enabled;
        const targetStatusText = serviceStatus.map[targetStatus].replace(
          '已',
          ''
        );

        return `确定要【${targetStatusText}】该客服吗?`;
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getListData();
    },
    beforeDestroy() {
      this.mttServiceId();
    },
    methods: {
      ...mapMutations('operation/service', ['mttServiceId']),
      ...mapActions('operation/service', ['actServices']),

      // 初始化模型
      initModel() {
        this.model = {
          status: '',
          keyword: '',
        };
      },
      // 获取列表数据
      async getListData() {
        this.loading = true;

        const success = await this.actServices({
          ...this.model,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除列表数据
      async removeListData() {
        const id = this.operateId;

        if (!id) return;

        this.removing = true;

        const res = await removeService(id);

        this.removing = false;

        return !!res;
      },
      // 切换列表项状态
      async switchListStatus() {
        const id = this.operateId;

        if (!id) return;

        this.switching = true;

        const statusEnum = serviceStatus.enum;
        const targetStatus =
          this.serviceObjStatus === statusEnum.enabled
            ? statusEnum.disabled
            : statusEnum.enabled;
        const params = {
          ids: [id],
          status: targetStatus,
        };
        const res = await updateServiceStatus(params);

        this.switching = false;

        return !!res;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getListData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getListData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行更新状态按钮单击
      handleTableRowSwitchClick({ _id }) {
        this.operateId = _id;
        this.showSwitchDialog = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick({ _id }) {
        this.operateId = _id;
        this.showRemoveDialog = true;
      },
      // 新增/编辑对话框确定按钮执行成功
      handleEditDialogOkClick() {
        this.getListData();
      },
      // 切换状态对话框关闭
      handleSwitchDialogClose() {
        this.operateId = '';
      },
      // 切换状态对话框确定按钮单击
      async handleSwitchDialogOkClick() {
        const success = await this.switchListStatus();

        if (!success) return;

        this.showSwitchDialog = false;

        this.getListData();
      },
      // 删除对话框关闭
      handleRemoveDialogClose() {
        this.operateId = '';
      },
      // 删除对话框确定按钮单击
      async handleRemoveDialogOkClick() {
        const success = await this.removeListData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(this.total, this.pageNo, this.pageSize);

        this.getListData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getListData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getListData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttServiceId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../style/mixin.scss';

  .operation-service {
    // 数据列表
    .operation-service-list {
      margin: 15px 0 0 -10px;

      .operation-service-item {
        display: inline-block;
        margin-left: 10px;

        .item-info {
          position: relative;
          overflow: hidden;

          .info-bg {
            width: 330px;
            height: 110px;
          }

          .info-qq,
          .info-wechat,
          .info-wechat-official-account {
            display: flex;
            height: 22px;
            padding: 0 5px;
            line-height: 22px;
            font-size: 13px;

            > span {
              display: inline-block;

              &:nth-of-type(1) {
                flex: 50px 0 0;
                color: #666;
              }

              &:nth-last-of-type(1) {
                flex-grow: 1;

                @include ellipsis();
              }
            }
          }

          .info-status {
            position: absolute;
            top: 5px;
            right: -15px;
            width: 60px;
            background-color: #fff;
            font-size: 12px;
            color: #999;
            text-align: center;
            transform: rotateZ(45deg);
            transform-origin: center;

            &.enabled {
              color: #67c23a;
            }
          }
        }

        .item-operate {
          display: flex;
          border-top: solid 1px #eee;

          .operate-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33.3%;
            padding: 5px 0;
            border-right: solid 1px #eee;

            &:nth-last-of-type(1) {
              border-right-color: transparent;
            }

            .el-button {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: auto;
              width: 24px;
              height: 24px;
              margin: 0;
            }
          }
        }

        &:hover {
          transition: transform 0.3s ease-in-out;
          transform: translateY(-5px);
        }
      }
    }

    // 分页器
    .operation-service-pagination {
      margin-top: 20px;
    }
  }
</style>
