<template>
  <div class="empty-placeholder">
    <div class="placeholder-img" :style="placeholderImgStyle" />

    <div class="placeholder-text">
      <slot v-if="$slots.default" />

      <template v-else>{{ placeholder }}</template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'empty-placeholder',
    props: {
      // 提示文案，优先级低于 slot
      placeholder: {
        type: String,
        default: '暂无数据',
      },
      // 缩放比例，默认图片: 宽度300px、高度200px
      zoom: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        // 占位符图片初始宽度
        width: 300,
        // 占位符图片初始高度
        height: 200,
      };
    },
    computed: {
      placeholderImgStyle() {
        const { zoom, width, height } = this;

        return {
          width: `${width * zoom}px`,
          height: `${height * zoom}px`,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .empty-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 13px;
    color: #909399;

    .placeholder-img {
      width: 300px;
      height: 200px;
      margin-bottom: 10px;
      background: url('../../assets/img/empty-placeholder.png') no-repeat center;
      background-size: cover;
    }
  }
</style>
